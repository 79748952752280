body {
  min-height: 100vh;
}

.is-sticky {
  position: sticky;
  top: 0;
}

.scroll-container {
  height: 100%;
}

.performance-container, .performance-table-container {
  overflow-x: auto;
}

.is-sticky-table-head {
  z-index: 100;
  position: sticky;
  left: 0;
}
/*# sourceMappingURL=index.52b161d3.css.map */
